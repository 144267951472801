import styled from 'styled-components';

export const Wrapper = styled.div`
  &:hover {
    cursor: pointer;
  }

  .faq {
    margin-bottom: 1.5rem;
    border-radius: 10px;
    background-color: #ffffff;
  }

  .faq-title {
    width: 100%;
    z-index: 200;
    border: none;
    padding: 0;
    outline: none;
    text-align: left;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #1b1b2a;
    position: relative;
    padding-right: 50px;
    @media (max-width: ${props => props.theme.screen.xs}) {
      padding-right: 2rem;
    }
  }

  .faq-content {
    position: relative;
    z-index: 200;
    padding-top: 15px;
    padding-right: 23px;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #1b1b2a;
    @media (max-width: ${props => props.theme.screen.xs}) {
      padding-right: 2rem;
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import BrandCarouselMobile from '@components/BrandCarousel/mobile';


const BrandsMobile = () => {
  return (
    <StaticQuery
      query={graphql`
      query {
        brands: allFile(filter:{extension:{regex:"/(jpeg|jpg|gif|png)/"},  sourceInstanceName:{eq:"brands"}}) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 250) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              },
              name
            }
          }
        },
      }
    `}
      render={data => (
        <BrandWrapper id="brands">
          <TitleContainer>
            <Line/>
            <h1>Brands</h1>
          </TitleContainer>
          <BrandCarouselContainer>
            <BrandCarouselMobile brands={data.brands.edges.map((brand) => ({name:brand.node.name, fluid: brand.node.childImageSharp.fluid}))}/>
          </BrandCarouselContainer>
        </BrandWrapper>
      )}
    />
  );
};

const BrandCarouselContainer = styled.div`
  padding: 0 2rem;
`
const BrandWrapper = styled.div`
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  z-index: 200;
`;

const TitleContainer = styled.div `
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  h1 {
    z-index: 200;
    padding-left: 1rem;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: right;
    color: #373a3d;
  }
  padding-bottom: 1rem;
`

const Line = styled.div `
  width: 80px;
  height: 1px;
  border: solid 0.5px #1b1b2a;
  margin-left: 2rem;
  z-index: 200;
`
export default BrandsMobile;

import React, { useState } from 'react';
import _ from 'lodash';
import {
  Carousel,
  CarouselItem
} from 'reactstrap';

import Img from 'gatsby-image';
import Next from '@static/new-web/slide_next.png';
import Prev from '@static/new-web/slide_prev.png';

import styled from 'styled-components';

const BrandCarousel = (props) => {
  const brands = props.brands;
  const numberOfSlide = _.chunk(brands,15).length;
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === numberOfSlide - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? numberOfSlide - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const slides = _(brands).chunk(15).map((group, index) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={index}
      >
        <BrandContainer>
            {_(group).chunk(5).map((row) =>
            <Row>
                {row.map(brand => 
                <LogoContainer>
                    <Img key={brand.name} fluid={brand.fluid}/>
                </LogoContainer>)}
            </Row>
            ).value() }
        </BrandContainer>
      </CarouselItem>
    );
  }).value();

  return (
    <BrandSliderContainer>
      <BrandSlider
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={false}
      >
        {slides}
      </BrandSlider>
      <ControlContainer>
        <img src={Prev} onClick={previous}/>
        <Seperator/>
        <img src={Next} onClick={next}/>
      </ControlContainer>
    </BrandSliderContainer>
  );
}

const BrandSliderContainer = styled.div`
  display:flex;
  flex-direction: column;
`;
const ControlContainer = styled.div `
  display: flex;
  justify-content: center;
  padding-top: 40px;
  img {
    width: 24px;
    height: 24px;
  }
`
const Seperator = styled.div `
  width: 1px;
  height: 24px;
  border: solid 1px #1b1b2a;
  margin: 0 8px;
`
const BrandSlider = styled(Carousel) `
  display: flex;
  height: 500px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`

const Row = styled.div`
  padding: 56px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LogoContainer = styled.div`
    max-width: 200px;
    max-height: 50px;
    flex: 1;
    margin: 0 3rem;
`
const BrandContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export default BrandCarousel;
import React from 'react';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Layout from '@common/Layout';
import Header from '@sections/Header';
import MobileHeader from '@sections/MobileHeader';
import HowItWork from '@sections/HowItWork';
import HowItWorkMobile from '@sections/HowItWorkMobile';
import Brands from '@sections/Brands';
import BrandsMobile from '@sections/BrandsMobile';
import FAQ from '@sections/FAQ';
import FAQMobile from '@sections/FAQMobile';
import OurStory from '@sections/OurStory';
import Footer from '@sections/Footer';
import CrowdFund from '@static/crowdfund.png';
import ExternalLink from '@common/ExternalLink';


const IndexPage = () => (
  <Layout>
    <DesktopContainer>
      <Header />
      <HowItWork />
      <Brands />
      <FAQ />
      <OurStory />
      <Footer />
      <Grid>
        <Line />
        <Line />
        <Line />
        <Line />
        <Line />
        <Line />
        <Line />
        <Line />
        <Line />
        <Line />
        <Line style={{ borderRight: 0 }} />
      </Grid>
      <Retangle />
      <Rectangle2 />
    </DesktopContainer>
    <MobileContainer>
      <MobileHeader/>
      <HowItWorkMobile/>
      <BrandsMobile/>
      <FAQMobile/>
      <OurStory />
      <Footer />
      <MobileGrid>
        <Line style={{ borderLeft: 'solid 1px #e3e3e3' }}/>
        <Line />
        <Line />
        <Line />
      </MobileGrid>
    </MobileContainer>
  </Layout>

);

const DesktopContainer = styled.div`
  background-color: #000000;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 1440px;
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: none;
  }
`
const MobileContainer = styled.div`
  display: none;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 100%;
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: flex;
  }
`

const Grid = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: none;
  }
`

const MobileGrid = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: flex;
  }
  padding: 0 2rem;
`

const Line = styled.div`
  flex: 1;
  height: 100%;
  opacity: 0.47;
  border-right: solid 1px #e3e3e3;
`

const Retangle = styled.div`
  width: 392px;
  height: 392px;
  background-color: rgba(227, 227, 227, 0.3);
  position: absolute;
  right: 0;
`
const Rectangle2 = styled.div`
  width: 785px;
  height: 839px;
  background-color: rgba(227, 227, 227, 0.3);
  position: absolute;
  bottom: 0;
  left: 0;
`
export default IndexPage;

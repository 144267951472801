import React, {useRef} from 'react';
import _ from 'lodash';

import Slider from "react-slick";

import Img from 'gatsby-image';

import styled from 'styled-components';

import Next from '@static/new-web/slide_next.png';
import Prev from '@static/new-web/slide_prev.png';

const setting = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slide"
};
const BrandCarousel = (props) => {
    const brands = props.brands;

    const slider = useRef();

    const next = () => {
        slider.current.slickNext();
    }

    const previous = () => {
        slider.current.slickPrev();
    }

    const slides = _(brands).chunk(12).map((group, index) => {
        return (
            <BrandContainer>
                {_(group).chunk(3).map((row) =>
                    <Row>
                        {row.map(brand =>
                            <LogoContainer>
                                <Img key={brand.name} fluid={brand.fluid} />
                            </LogoContainer>)}
                    </Row>
                ).value()}
            </BrandContainer>
        );
    }).value();

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <BrandSlider
                ref={slider}
                {...setting}
            >
                {slides}
            </BrandSlider>
            <ControlContainer>
                <img src={Prev} onClick={previous} />
                <Seperator />
                <img src={Next} onClick={next} />
            </ControlContainer>
        </div>
    );
}

const BrandSlider = styled(Slider)`
  display: none;
  flex-direction: column;
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: flex;
  }
`
const Row = styled.div`
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const LogoContainer = styled.div`
    max-width: 250px;
    flex: 1;
    padding: 0 1rem;
`
const BrandContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const ControlContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
  img {
    width: 24px;
    height: 24px;
    z-index: 200;
  }
`
const Seperator = styled.div`
  width: 1px;
  height: 24px;
  border: solid 1px #1b1b2a;
  margin: 0 8px;
`

export default BrandCarousel;
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import FacebookIcon from '@static/icons/facebook.png';
import InstagramIcon from '@static/icons/instagram.png';
import MenuIcon from '@static/new-web/Group 4.png';
import PriceImg from '@static/new-web/price.png';
import CoverImg from '@static/new-web/cover.png';
import HeaderSubImg from '@static/new-web/header_sub_image.png';
import DrobeboxLogo from '@static/drobebox_logo.png';
import Apple from '@static/new-web/ios.png';
import Google from '@static/new-web/android.png';
import ArrowDown from '@static/new-web/arrow-down.png';

import AnchorLink from 'react-anchor-link-smooth-scroll'

import ExternalLink from '@common/ExternalLink';


const SOCIAL = [
  {
    icon: FacebookIcon,
    link: 'https://www.facebook.com/DrobeBox/',
  },
  {
    icon: InstagramIcon,
    link: 'https://www.instagram.com/drobebox.official/',
  }
];

const Header = () => {
  return (
    <HeaderWrapper id="header">
      <Row>
        <Brand>
          <Link to='/'>
            <Logo src={DrobeboxLogo} alt="Logo" />
          </Link>
        </Brand>
        <NavContainer>
          <BlogLink href='http://blog.drobebox.com/'>
            Blog
          </BlogLink>
          <SocialIcons>
            {SOCIAL.map(({ icon, link }) => (
              <ExternalLink href={link}>
                <img src={icon} alt="link" />
              </ExternalLink>
            ))}
          </SocialIcons>
        </NavContainer>
      </Row>
      <Container>
        <HeaderSubImage src={HeaderSubImg} />
        <TitleContainer>
          <Row>
            <Title>
              Tủ đồ vô tận
                </Title>
          </Row>
          <Row>
            <SubTitleContainer>
              <Line />
              <SubTitle>
                Tự mua sắm, tự giặt giũ và luôn sẵn sàng cùng bạn cho mọi sự thay đổi.
                  </SubTitle>
            </SubTitleContainer>
            <div>
              <Title>
                nằm gọn
                  </Title>
              <Title>
                trong tay
                  </Title>
              <Row>
                <Only>
                  Chỉ với
                    </Only>
                <Price src={PriceImg} />
              </Row>
            </div>
          </Row>
        </TitleContainer>
        <Cover src={CoverImg} />
        <YellowLine />
        <AnchorLink href='#story'>
          <WhyContainer>
            <Why>
              Our story<br />
                </Why>
            <img src={ArrowDown} />
          </WhyContainer>
        </AnchorLink>
      </Container>
      <AppDownloadContainer>
        <AnchorLink href='#howitwork'>
          <HowItWorkContainer>
            <HowItWork>
              How it work
            </HowItWork>
            <img src={ArrowDown} />
          </HowItWorkContainer>
        </AnchorLink>
        <AppDownloadLink href='https://apps.apple.com/us/app/drobebox/id1497642900?ls=1' style={{ marginLeft: '59px', marginRight: '29px' }}>
          <img src={Apple} alt='apple' />
        </AppDownloadLink>
        <AppDownloadLink href='https://play.google.com/store/apps/details?id=com.drobebox.drobebox'>
          <img src={Google} alt='google' />
        </AppDownloadLink>
      </AppDownloadContainer>
    </HeaderWrapper>
  );
};

const Row = styled.div`
  display: flex;
`

const HeaderWrapper = styled.header`
  position: relative;
  background-color: ${props => props.theme.color.white.regular};
  margin-top: 44px;
  margin-left: 24px;
  margin-right: 24px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 700px;
`;

const Brand = styled.div`
  z-index: 200;
  ${props => props.theme.font_size.large};
  @media (max-width: ${props => props.theme.screen.sm}) {
    img {
      width: 6rem;
    };
  }
  padding-left: 3rem;
  padding-top: 2rem;
`;

const Logo = styled.img`
  background-color: ${props => props.theme.color.white.regular};
  max-height: 69px;
`

const NavContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  z-index: 200;
`

const SocialIcons = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin: 0 1rem;
  }
  padding-right: 90px;
`;

const BlogLink = styled(ExternalLink)`
  padding-right: 27px;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: #1b1b2a;
  z-index: 200;
`
const Title = styled.h1`
  font-size: 79px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #0e0f1e;
  text-transform: uppercase;
  z-index: 200;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-left: 67px;
  z-index: 200;
`

const SubTitle = styled.h4`
  padding-top: 1rem;  
  width: 195px;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: #1b1b2a;
  z-index: 200;
`

const SubTitleContainer = styled.div`
  padding-right: 2rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  z-index: 200;
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  border: solid 2px #000000;
`

const Container = styled(Row)`
`

const Only = styled.p`
  font-size: 44px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #0e0f1e;
  padding-right: 30px;
`

const Price = styled.img`
  width: 208px;
`

const Cover = styled.img`
  width: 393px;
  height: 577px;
  position: absolute;
  right: 237px;
  z-index: 150;
`

const YellowLine = styled.div`
  width: 3px;
  height: 41px;
  background: #ffb21f;
  position: absolute;
  right: 106px;
  top: 228px;
  z-index:200;
`

const HeaderSubImage = styled.img`
  width: 107px;
  height: 107px;
  margin-top: 156px;
  z-index: 200;
`

const AppDownloadContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding-left: 62px;
`

const AppDownloadLink = styled(OutboundLink)`
  z-index: 200;
  width: 182px;
  height: 54px;
  img {
    width: 182px;
    height: 54px;
  }
`
const HowItWorkContainer = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: flex-end;
  img {
    width: 45px;
    height: 45px;
    border-radius: 5px;
    background-color: #ffb21f;
    z-index: 200;
  }
`

const HowItWork = styled.p`
  z-index: 200;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  font-size: 18px;
  color: #0325c8;
  font-weight: 300;
  padding-top: 10px;
  line-height: 15px;
`

const WhyContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  bottom: 22px;
  right: 106px;
  img {
    width: 65px;
    height: 65px;
    border-radius: 5px;
    background-color: #ffb21f;
    z-index: 200;
  }
`

const Why = styled.p`
  z-index: 200;
  width: 240px;
  font-size: 44px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #1b1b2a;
  text-transform: uppercase;
`
export default Header;

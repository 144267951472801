import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import FacebookIcon from '@static/icons/facebook.png';
import InstagramIcon from '@static/icons/instagram.png';

import ExternalLink from '@common/ExternalLink';

import AppLogo from '@static/app_icon.png';


const SOCIAL = [
    {
        icon: InstagramIcon,
        link: 'https://www.instagram.com/drobebox.official/',
    },
    {
        icon: FacebookIcon,
        link: 'https://www.facebook.com/DrobeBox/',
    }
];


const Footer = () =>
  <div>
    <FooterSection>
      <Row style={{alignItems: 'center'}}>
        <Logo src={AppLogo}/>
        <div style={{flex: 1, flexDirection: 'column'}}>
          <LinkContainer>
              <Link to='/cau-chuyen'>Về Chúng Tôi</Link>
              <ExternalLink href='https://blog.drobebox.com'>Blog</ExternalLink>
              <Link to='/dieu-khoan-su-dung'>Điều Khoản Sử Dụng</Link>
              <Link to='/chinh-sach-bao-mat'>Chính Sách Bảo Mật</Link>
          </LinkContainer>
        </div>
      </Row>
      <Row>
        <CompanyInfo>
              <p>Công ty TNHH Drobebox</p>
              <p>Địa chỉ: 71 Điện Biên Phủ, Phường 15, Bình Thạnh</p>
              <p>Mã số doanh nghiệp: 0315996602 </p>
          </CompanyInfo>
          <SocialIcons>
            {SOCIAL.map(({ icon, link }) => (
                <ExternalLink href={link}>
                    <img src={icon} alt="link" />
                </ExternalLink>
            ))}
        </SocialIcons>
      </Row>
    </FooterSection>
    <MobileSection>
      <SocialIcons style={{justifyContent: 'center'}}>
          {SOCIAL.map(({ icon, link }) => (
              <ExternalLink href={link}>
                  <img src={icon} alt="link" />
              </ExternalLink>
          ))}
      </SocialIcons>
      <CompanyInfoMobile>
        <p>Công ty TNHH Drobebox</p>
        <p>Địa chỉ: 71 Điện Biên Phủ, Phường 15, Bình Thạnh</p>
        <p>Mã số doanh nghiệp: 0315996602 </p>
      </CompanyInfoMobile>
      <Logo src={AppLogo}/>
      <div style={{flex: 1, flexDirection: 'column'}}>
        <LinkContainer>
            <Link to='/cau-chuyen'>Về Chúng Tôi</Link>
            <ExternalLink href='https://blog.drobebox.com'>Blog</ExternalLink>
            <Link to='/dieu-khoan-su-dung'>Điều Khoản Sử Dụng</Link>
            <Link to='/chinh-sach-bao-mat'>Chính Sách Bảo Mật</Link>
        </LinkContainer>
      </div>
    </MobileSection>
  </div>

const Row = styled.div`
  flex: 1;
  display: flex;
`

const Logo = styled.img`
  position: relative;
  z-index: 200;
  width: 73px;
  height: 61px;
`

const FooterSection = styled.div`
  padding-left: 137px;
  position: relative;
  display: flex;
  background-color: ${props => props.theme.color.white.regular};
  margin-bottom: 24px;
  margin-left: 24px;
  margin-right: 24px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-bottom: 140px;
  a {
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.76;
    letter-spacing: normal;
    color: #1b1b2a;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: none;
  }
`

const MobileSection = styled.div`
  position: relative;
  display: none;
  flex-direction: column;
  background-color: ${props => props.theme.color.white.regular};
  margin-bottom: 24px;
  margin-left: 24px;
  margin-right: 24px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-bottom: 2rem;
  align-items: center;
  a {
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.76;
    letter-spacing: normal;
    color: #1b1b2a;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: flex
  }
`

const LinkContainer = styled(Row)`
  flex-direction: column;
  align-items: flex-start;
  padding-left: 31px;
  a {
    z-index: 200;
    text-decoration: underline;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-items: center;
    padding-left: 0;
  }
`
const SocialIcons = styled.div`
  display: flex;
  padding: 0 1rem;
  img {
    position: relative;
    margin: 0 1rem;
    width: 24px;
    height: 24px;
    z-index: 200;
  }

  p {
    padding-right: 1rem;
    color: ${props => props.theme.color.white.regular};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
`;

const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-left: 53px;
  p {
    z-index: 200;
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.76;
    letter-spacing: normal;
    text-align: right;
    color: #1b1b2a;
  }
`

const CompanyInfoMobile = styled(CompanyInfo)`
  justify-content: center;
  padding-left: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  p {
    z-index: 200;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.77;
    letter-spacing: normal;
    text-align: center;
    color: #1b1b2a;
  }
`
export default Footer;

import React from 'react';
import styled from 'styled-components';
import Step1 from '@static/new-web/step_1.png';
import Step2 from '@static/new-web/step_2.png';
import Step3 from '@static/new-web/step_3.png';
import Step4 from '@static/new-web/step_4.png';
import StepNumber1 from '@static/new-web/step_number_1.png';
import StepNumber2 from '@static/new-web/step_number_2.png';
import StepNumber3 from '@static/new-web/step_number_3.png';
import StepNumber4 from '@static/new-web/step_number_4.png';
import ArrowLine from '@static/new-web/arrow_line.png';


const HowItWork = () => {
  return (
    <HowItWorkWrapper id="howitwork">
      <TitleContainer>
        <Line />
        <YellowLine />
        <h1>
          How<br />
              it work
            </h1>
      </TitleContainer>
      <StepContainer>
        <Row>
          <Step>
            <StepImg src={Step1} />
            <StepDescContainer>
              <StepNumber src={StepNumber1} />
              <NextStepLine src={ArrowLine} />
              <h1>
                Trở thành
                  </h1>
              <h2>
                Thành Viên
                  </h2>
              <h3>
                Chỉ với 50k/ngày tương đương với 1tr5 mỗi tháng với nhiều ưu đãi
                  </h3>
            </StepDescContainer>
          </Step>
          <Step style={{ marginLeft: 24 }}>
            <StepImg src={Step2} />
            <StepDescContainer>
              <StepNumber src={StepNumber2} />
              <NextStepLine src={ArrowLine} />
              <h1>
                Mỗi lần
                  </h1>
              <h2>
                5 Món*
                  </h2>
              <h3>
                Tuỳ ý lựa chọn từ hàng ngàn thiết kế mới nhất đến từ các thương hiệu hàng đầu
                  </h3>
            </StepDescContainer>
            <HintContainer>
              <Mark>*</Mark>
              <Hint>Thay đổi không giới hạn, trung bình <b>20-30 sản phẩm</b> mỗi tháng</Hint>
            </HintContainer>
          </Step>
        </Row>
        <Row>
          <Step>
            <StepImg src={Step3} />
            <StepDescContainer>
              <StepNumber src={StepNumber3} />
              <NextStepLine src={ArrowLine} />
              <h1>
                nhận sản phẩm
                  </h1>
              <h2>
                Tại Nhà
                  </h2>
              <h3>
                Drobebox sẽ chuyển các sản phẩm đến tận cửa nhà của bạn hoàn toàn miễn phí
                  </h3>
            </StepDescContainer>
          </Step>
          <Step style={{ marginLeft: 24 }}>
            <StepImg src={Step4} />
            <StepDescContainer>
              <StepNumber src={StepNumber4} />
              <NextStepLine src={ArrowLine} />
              <h1>
                chỉ hoàn trả
                  </h1>
              <h2>
                Khi bạn muốn
                  </h2>
              <h3>
                Bạn chỉ cần trả lại các sản phẩm khi không còn nhu cầu sử dụng.
                  </h3>
            </StepDescContainer>
          </Step>
        </Row>
      </StepContainer>
    </HowItWorkWrapper>
  );
};

const Row = styled.div`
  display: flex;
  padding: 58px 0;
`
const HowItWorkWrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.white.regular};
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 107px;
`;

const TitleContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  h1 {
    z-index: 200;
    text-align: right;
  }
`

const Line = styled.div`
  width: 131px;
  height: 1px;
  border: solid 1px #1b1b2a;
  margin-top: 28px;
  margin-left: 40px;
  z-index: 200;
`

const YellowLine = styled.div`
  width: 3px;
  height: 41px;
  background: #ffb21f;
  position: absolute;
  left: 392px;
  top: 30px;
  z-index:200;
`
const StepContainer = styled.div`
  padding-top: 68px;
`
const Step = styled.div`
  flex: 1;
  display: flex;
  z-index: 200;
  position: relative;
`
const StepDescContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 40px;
  h1 {
    height: 44px;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    color: #1b1b2a;
  }
  h2 {
    height: 44px;
    font-size: 24px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    color: #0325c8;
    text-transform: uppercase;
  }
  h3 {
    width: 206px;
    height: 66px;
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #1b1b2a;
  }
`
const StepImg = styled.img`
  width: 262px;
  height: 262px;
`
const StepNumber = styled.img`
  width: 64px;
`
const NextStepLine = styled.img`
  width: 141px;
`
const HintContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: -58px;
  display: flex;
`

const Mark = styled.span`
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #0325c8;
  padding-right: 10px;
`
const Hint = styled.span`
  font-size: 17px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: normal;
  color: #9ca1ab;
  b {
    color: #0e0f1e;
    font-weight: 300;
  }
`
export default HowItWork;

import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import BrandCarousel from '@components/BrandCarousel';


const Brands = () => {
  return (
    <StaticQuery
      query={graphql`
      query {
        brands: allFile(filter:{extension:{regex:"/(jpeg|jpg|gif|png)/"},  sourceInstanceName:{eq:"brands"}}) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 250) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              },
              name
            }
          }
        },
      }
    `}
      render={data => (
        <BrandWrapper id="brands">
          <TitleContainer>
            <Line/>
            <h1>Brands</h1>
          </TitleContainer>
          <BrandCarouselContainer>
            <BrandCarousel brands={data.brands.edges.map((brand) => ({name:brand.node.name, fluid: brand.node.childImageSharp.fluid}))}/>
          </BrandCarouselContainer>
        </BrandWrapper>
      )}
    />
  );
};

const BrandCarouselContainer = styled.div`
  padding: 0 68px;
`
const BrandWrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.white.regular};
  padding-top: 82px;
  padding-bottom: 82px;
  z-index: 200;
  box-shadow: 2px 2px 9px 1px rgba(190, 190, 190, 0.5);
`;

const TitleContainer = styled.div `
  display: flex;
  position: relative;
  justify-content: flex-start;
  padding-left: 136px;
  h1 {
    z-index: 200;
    text-align: right;
    padding-left: 2rem;
  }
  padding-bottom: 40px;
`

const Line = styled.div `
  width: 131px;
  height: 1px;
  border: solid 0.5px #1b1b2a;
  margin-top: 28px;
  margin-left: 40px;
  z-index: 200;
`
export default Brands;

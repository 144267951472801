import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ExternalLink from '@common/ExternalLink';
import ArrowDown from '@static/new-web/arrow-down.png';
import Email from '@static/new-web/email.png';


const OurStory = () => {
  return (
    <StoryWrapper id="story">
      <OurStoryContainer>
        <TitleContainer>
          <Line/>
          <h1>Our Story</h1>
        </TitleContainer>
        <StoryContent>
          <h1>Thay Đổi Thế Giới Từ Tủ Đồ Của Bạn.</h1>
          <StoryPreviewContainer>
            <p>Sẽ thế nào nếu chúng ta định nghĩa lại thời trang ?<br/><br/>
              Đã từ lâu, thời trang luôn bị gắn chặt với tính sở hữu, mỗi món đồ trong tủ, mỗi món đồ bạn mang trên người, đều là một lần mua sắm.<br/><br/>
              Mặc đẹp luôn là đặc quyền của phái đẹp, chỉ khi ta tự tin với bộ đồ trên người mỗi khi bước ra đường, ta mới trở thành phiên bản tốt nhất của bản thân, mới có thể có một ngày trọn vẹn.
            </p>
            <Link style={{display: 'flex', flexDirection: 'column'}} to='/cau-chuyen'>
              <img src={ArrowDown} />
              <Link to='/cau-chuyen'>Read more</Link>
            </Link>
          </StoryPreviewContainer>
        </StoryContent>
        <DesktopLine style={{alignSelf: 'flex-end', marginRight: 0}}/>
      </OurStoryContainer>
      <PartnerContactContainer>
        <DesktopTitle>
          <Line/>
          <h1 style={{width: '260px', zIndex: 200, height: 'auto'}}>Bạn là thương hiệu thời trang ?</h1>
        </DesktopTitle>
        <MobileTitle>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center',width: '100%', justifyContent: 'flex-end'}}>
            <Line/>
            <h1 style={{zIndex: 200, height: 'auto', width: 'auto'}}>Bạn là</h1>
          </div>
          <h1>thương hiệu ?</h1>
        </MobileTitle>
        <p>
          Đồng hành cùng chúng tôi trong cuộc cách mạng thời trang 4.0 ngay hôm nay
        </p>
        <div style={{display: 'flex', paddingTop: 29}}>
          <EmailContainer>
            <img src={Email}/>
          </EmailContainer>
          <ExternalLink href='mailto:partner@drobebox.com'>
            <EmailAddress>partner@drobebox.com</EmailAddress>
          </ExternalLink>
        </div>
      </PartnerContactContainer>
      <Rectangle2/>
    </StoryWrapper>
  );
};

const StoryWrapper = styled.div`
  background-color: ${props => props.theme.color.white.regular};
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 24px;
  padding-bottom: 54px;
  padding-left: 107px;
  display: flex;
  @media (max-width: ${props => props.theme.screen.xs}) {
    position: relative;
    flex-direction: column;
    padding-left: 0;
    margin-left: 2rem;
    margin-right: 2rem;
    padding-top: 3rem;
  }
`;

const OurStoryContainer = styled.div `
  display: flex;
  position: relative;
  width: 655px;
  flex-direction: column;
  @media (max-width: ${props => props.theme.screen.xs}) {
    width: 100%;
    margin-right: 2rem;
  }
`;

const StoryContent = styled.div`
  display: flex;
  padding-top: 27px;
  @media (max-width: ${props => props.theme.screen.xs}) {
    flex-direction: column;
    align-items: flex-end;
    padding-top: 2rem;
  }
  h1 {
    width: 246px;
    z-index: 200;
    font-size: 44px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: normal;
    color: #0325c8;
    margin-right: 15px;
    @media (max-width: ${props => props.theme.screen.xs}) {
      font-size: 28px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      color: #0325c8;
      margin-right: 0;
      text-align: right;
      padding-bottom: 2rem;
      padding-right: 1rem;
    }
  }
`;
const StoryPreviewContainer = styled.div `
  display: flex;
  flex-direction: column;
  p {
    width: 360px;
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #1b1b2a;
    @media (max-width: ${props => props.theme.screen.xs}) {
      width: 100%;
      z-index: 200;
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #1b1b2a;
    }
  }
  img {
    z-index: 200;
    margin-top: 20px;
    width: 24px;
    height: 24px;
  }
  a {
    z-index: 200;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #0325c8;
    @media (max-width: ${props => props.theme.screen.xs}) {
      align-items: center;
      padding-bottom: 3rem;
      padding-top: 1rem;
    }
  }
`
const PartnerContactContainer = styled.div `
  display: flex;
  flex-direction: column;
  p {
    z-index: 200;
    width: 258px;
    height: 66px;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #0325c8;
    margin-left: 261px;
    margin-top: 20px;
    @media (max-width: ${props => props.theme.screen.xs}) {
      width: 100%;
      font-size: 17px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: right;
      color: #0325c8;
      margin-left: 0;
      margin-top: 1rem;
      padding-right: 25%;
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
  @media (max-width: ${props => props.theme.screen.xs}) {
    align-items: center;
    justify-content: flex-end;
  }
  h1 {
    z-index: 200;
    text-align: right;
    font-size: 44px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #1b1b2a;
    height: 61px;
    @media (max-width: ${props => props.theme.screen.xs}) {
      height: auto;
      font-size: 28px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: right;
      color: #1b1b2a;
      padding-right: 1rem;
    }
  }
`

const Line = styled.div`
  width: 131px;
  height: 1px;
  border: solid 1px #1b1b2a;
  margin-top: 28px;
  margin-right: 20px;
  z-index: 200;
  @media (max-width: ${props => props.theme.screen.xs}) {
    width: 80px;
    margin-right: 1rem;
    margin-top: 0;
  }
`

const DesktopLine = styled(Line)`
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: none;
  }
`

const DesktopTitle = styled(TitleContainer) `
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: none;
  }
`

const MobileTitle = styled(TitleContainer) `
  display: none;
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: flex;
    flex-direction: column;
    h1 {
      padding-right: 0;
      text-align: right;
      width: 100%;
    }
    margin-right: 25%;
  }
`

const EmailContainer = styled.div `
  margin-left: 130px;
  width: 130px;
  height: 64px;
  background-color: #0325c8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  img {
    width: 24px;
    height: 24px;
  }
  @media (max-width: ${props => props.theme.screen.xs}) {
    margin-left: 0;
  }
`
const EmailAddress = styled.div `
  position: relative;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 264px;
  height: 64px;
  background-color: #ffb21f;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #ffffff;
`

const Rectangle2 = styled.div`
  display: none;
  width: calc(100vw - 2rem);
  height: calc((100vw - 2rem) * 1.486);
  background-color: rgba(227, 227, 227, 0.3);
  position: absolute;
  top: 0;
  right: 0;
  @media (max-width: ${props => props.theme.screen.xs}) {
    display: block;
  }
`
export default OurStory;

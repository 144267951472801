import React, { useRef } from 'react';
import styled from 'styled-components';

import Slider from "react-slick";

import Next from '@static/new-web/slide_next.png';
import Prev from '@static/new-web/slide_prev.png';

import Step1 from '@static/new-web/step_1.png';
import Step2 from '@static/new-web/step_2.png';
import Step3 from '@static/new-web/step_3.png';
import Step4 from '@static/new-web/step_4.png';
import StepNumber1 from '@static/new-web/step_number_1.png';
import StepNumber2 from '@static/new-web/step_number_2.png';
import StepNumber3 from '@static/new-web/step_number_3.png';
import StepNumber4 from '@static/new-web/step_number_4.png';
import ArrowLine from '@static/new-web/arrow_line.png';


const setting = {
  dots: false,
  infinite: true,
  autoplay: true,
  arrows: false,
  autoplaySpeed: 5000,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const HowItWork = () => {
  const slider = useRef();

  const next = () => {
    slider.current.slickNext();
  }

  const previous = () => {
    slider.current.slickPrev();
  }
  return (
    <HowItWorkWrapper id="howitwork">
      <TitleContainer>
        <Line />
        <h1>
          How<br />
          it work
        </h1>
      </TitleContainer>
      <StepContainer>
        <StepSliderContainer>
          <StepSlider
            ref={slider}
            {...setting}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Step>
                <div style={{ flex: 1 }}>
                  <StepImg src={Step1} />
                </div>
                <StepDescContainer>
                  <StepNumber src={StepNumber1} />
                  <NextStepLine src={ArrowLine} />
                  <h1>
                    Trở thành
                  </h1>
                  <h2>
                    Thành Viên
                  </h2>
                  <h3>
                    Chỉ với 50k/ngày tương đương với 1tr5/tháng với nhiều ưu đãi
                    </h3>
                </StepDescContainer>
              </Step>
              <Step>
                <div style={{ flex: 1 }}>
                  <StepImg src={Step2} />
                </div>
                <StepDescContainer>
                  <StepNumber src={StepNumber2} />
                  <NextStepLine src={ArrowLine} />
                  <h1>
                    Mỗi lần
                  </h1>
                  <h2>
                    5 Món
                    </h2>
                  <h3>
                    Tuỳ ý lựa chọn từ hàng ngàn thiết kế mới nhất đến từ các thương hiệu hàng đầu
                        </h3>
                </StepDescContainer>
              </Step>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Step>
                <div style={{ flex: 1 }}>
                  <StepImg src={Step3} />
                </div>
                <StepDescContainer>
                  <StepNumber src={StepNumber3} />
                  <NextStepLine src={ArrowLine} />
                  <h1>
                    nhận sản phẩm
                  </h1>
                  <h2>
                    Tại Nhà
                  </h2>
                  <h3>
                    Drobebox sẽ chuyển các sản phẩm đến tận cửa nhà của bạn hoàn toàn miễn phí
                  </h3>
                </StepDescContainer>
              </Step>
              <Step>
                <div style={{ flex: 1 }}>
                  <StepImg src={Step4} />
                </div>
                <StepDescContainer>
                  <StepNumber src={StepNumber4} />
                  <NextStepLine src={ArrowLine} />
                  <h1>
                    chỉ hoàn trả
                  </h1>
                  <h2>
                    Khi bạn muốn
                  </h2>
                  <h3>
                    Bạn chỉ cần trả lại các sản phẩm khi không còn nhu cầu sử dụng.
                  </h3>
                </StepDescContainer>
              </Step>
            </div>
          </StepSlider>
          <ControlContainer>
            <img src={Prev} onClick={previous} />
            <Seperator />
            <img src={Next} onClick={next} />
          </ControlContainer>
        </StepSliderContainer>
      </StepContainer>
      <YellowLine />
    </HowItWorkWrapper>
  );
};

const HowItWorkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${props => props.theme.color.white.regular};
  padding-top: 22px;
  padding-bottom: 22px;
`;

const TitleContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  h1 {
    z-index: 200;
    text-align: right;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: right;
    color: #373a3d;
  }
`

const Line = styled.div`
  width: 90px;
  height: 1px;
  border: solid 1px #1b1b2a;
  margin-top: 1rem;
  z-index: 200;
`

const StepContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
`
const StepSliderContainer = styled.div`
  display:flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-right: 2rem;
`;
const Step = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  z-index: 200;
  position: relative;
  padding: 1rem 0;
`
const StepDescContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 200;
  h1 {
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #1b1b2a;
    padding-left: 1rem;
    padding-top: 1rem;
  }
  h2 {
    font-size: 18px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #0325c8;
    text-transform: uppercase;
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  h3 {
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #1b1b2a;
    padding-left: 1rem;
  }
`
const StepImg = styled.img`
  width: 100%;
`
const StepNumber = styled.img`
  width: 40%;
  margin-left: 1rem;
`
const NextStepLine = styled.img`
  width: 60%;
  margin-left: 1rem;
`

const Seperator = styled.div`
  width: 1px;
  height: 24px;
  border: solid 1px #1b1b2a;
  margin: 0 8px;
`
const StepSlider = styled(Slider)`
  display: flex;
  z-index: 200;
`

const ControlContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  img {
    width: 24px;
    height: 24px;
    z-index: 200;
  }
`
const YellowLine = styled.div`
  width: 4px;
  height: 41px;
  background: #ffb21f;
  position: absolute;
  bottom: 0;
  right: 2rem;
  z-index:200;
`

export default HowItWork;

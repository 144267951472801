import React from 'react';
import styled from 'styled-components';

import FaqItem from '@common/FaqItem';

const FAQS = [
  {
    title: 'Chính xác thì Drobebox là gì ?',
    content: () => (
      <>
        Nói đơn giản thì Drobebox là một tủ đồ siêu to khủng lồ nằm gọn trong điện thoại của bạn. <br/>
        Chỉ với chưa đến 50k mỗi ngày, bạn có thể thuê 20 đến 30 sản phẩm mỗi tháng. Không cần ra cửa hàng, không cần phải giặt ủi, mà ngày nào cũng có đồ mới để mang.<br/>
        Drobebox giúp bạn tự do trải nghiệm nhiều thương hiệu hàng đầu, nhiều phong cách và được xinh đẹp mỗi ngày, chỉ đơn giản như order một cốc trà sữa.
      </>
    ),
  },
  {
    title: 'Thành viên của Drobebox có quyền lợi gì ?',
    content: () => (
      <>
        Tất cả thành viên của Drobebox được truy cập vào &quot;tủ đồ trên mây&quot; của Drobebox vào sử dụng các sản phẩm tuỳ thích, không giới hạn<br/>
        Mỗi thành viên có thể giữ và sử dụng cùng lúc tối đa 5 sản phẩm. Tuy nhiên Drobebox không giới hạn số lần thay đổi sản phẩm trong tháng, vì vậy mỗi thành viên có thể trải nghiệm trung bình 20-30 sản phẩm mới mỗi tháng<br/>
        Giao giao nhận tận nơi, sản phẩm luôn được giặt ủi phẳng phiu trước khi đến tay, stylist riêng 24/7 với công nghệ AI, chỉ là một vài trong số rất nhiều đặc quyền của thành viên Drobebox.<br/>
        Không chỉ vậy, các thành viên của Drobebox hoàn toàn có thể lựa chọn giữ lại các sản phẩm yêu thích, với mức giá ưu đãi lên đến 80% so với giá bán lẻ.
      </>
    ),
  },
  {
    title: 'Chất lượng sản phẩm có đảm bảo?',
    content: () => (
      <>
        Toàn bộ sản phẩm của Drobebox sau khi sử dụng đều được giặt sấy bởi đối tác giặt sấy hàng đầu, Mr.Jeff, để đảm bảo chất lượng sản phẩm tốt nhất khi đến tay người dùng. <br/>
        Bên cạnh các quy trình nghiêm ngặt khi phân loại, xử lý các sản phẩm, toàn bộ sản phẩm của Drobebox đều được khử trùng bằng tia UV trước khi có thể nhập kho và đến tay khách hàng.<br/>
        Ngoài ra, toàn bộ sản phẩm đều trải qua quy trình kiểm tra chất lượng kỹ lưỡng trước khi nhập và xuất kho.<br/>
        Với quy trình nghiêm ngặt, các sản phẩm của Drobebox luôn trong tình trạng như mới để có thể mang đến trải nghiệm tốt nhất cho khách hàng.
      </>
    ),
  },
  {
    title: 'Làm sao để nhận sản phẩm ?',
    content: () => (
      <>
        Sau khi kích hoạt thành viên thành công, nàng có thể truy cập vào tủ đồ của Drobebox và lựa chọn bất kỳ sản phẩm mình yêu thích và tiến hành đặt hàng.<br/>
        Drobebox sẽ xử lý và chuyển các sản phẩm đến địa chỉ nàng lựa chọn trong vòng 24h.<br/>
      </>
    ),
  },
  {
    title: 'Tôi có thể giữ sản phẩm trong bao lâu ?',
    content: () => (
      <>
        Drobebox không có bất kỳ giới hạn nào về thời gian giữ sản phẩm, chúng tôi tin thời trang nên đi kèm tự do và tiện lợi.<br/>
        Tuy nhiên để giảm thiểu rủi ro hư hại sản phẩm, nàng không nên tự giặt các sản phẩm mà hoàn trả ngay khi không còn nhu cầu sử dụng. Chi phí vận chuyển cũng như giặt giũ đã bao gồm trong phí thành viên nên nàng đừng ngại &quot;giải phóng&quot; mình khỏi việc giặt giũ nhé.
      </>
    ),
  },
  {
    title: 'Làm sao để đổi sản phẩm mới ?',
    content: () => (
      <>
        Nếu nàng vẫn còn vị trí trống trong tủ đồ, nàng hoàn toàn có thể lựa chọn và đặt hàng sản phẩm mới bất kỳ lúc nào.<br/>
        Nếu nàng đã sử dụng hết vị trí trống, nàng cần trả hoặc mua sản phẩm mà nàng đang giữ để có thể tiếp tục đặt hàng.<br/>
      </>
    ),
  },
  {
    title: 'Làm sao để trả lại sản phẩm ?',
    content: () => (
      <>
        Nàng chỉ cần chọn &quot;Trả Đồ&quot; trên ứng dụng điện thoại và hoàn thành theo hướng dẫn. Nhân viên của Drobebox sẽ đến nhận sản phẩm tại địa chỉ nàng lựa chọn trong vòng 24h.<br/>
        Drobebox sẽ kiểm tra và báo đến nàng bất kỳ mất mát hay hư hại nào của các sản phẩm kèm chi phí gia tăng tương ứng.<br/>
        Sau khi kiểm tra và xác nhận hoàn tất, nàng sẽ nhận được thông báo trên ứng dụng Drobebox về việc thay đổi hạn mức và có thể tiếp tục lựa chọn các sản phẩm mới.<br/>
        Quy trình trả sản phẩm thông thường sẽ hoàn thành trong vòng 24h kể từ lúc nhận được yêu cầu.
      </>
    ),
  },
  {
    title: 'Bao lâu thì Drobebox thêm sản phẩm mới ?',
    content: () => (
      <>
        Tủ đồ của Drobebox được cập nhật hàng tuần.<br/>
        Các sản phẩm mới có thể đến từ các thương hiệu hiện có trên Drobebox hoặc những thương hiệu mới.<br/>
        Toàn bộ sản phẩm đều được team Fashion của Drobebox lựa chọn kỹ lưỡng dựa trên thiết kế, chất liệu, hoàn thiện cũng như phản hồi từ khách hàng.<br/>
        Trung bình, Drobebox thường cập nhật 50-80 thiết kế mỗi tháng, nên nàng có thể yên tâm sẽ luôn có "quá nhiều để mặc".
      </>
    ),
  },
  {
    title: 'Tôi có bị thu phí nếu sản phẩm hư hỏng ?',
    content: () => (
      <>
        Đối với các hư hại, vết bẩn phổ thông (wear and tear), Drobebox hoàn toàn sẽ không thu thêm chi phí khắc phục (đã bao gồm trong phí thành viên)<br/>
        Tuy nhiên đối với các hư hại, vết bẩn khó khắc phục, hoặc mất sản phẩm, Drobebox sẽ thu thêm một khoản phí để khắc phục những thiệt hại này.<br/>
        Khoản phí này được tính toán sau khi Drobebox nhận lại và kiểm tra sản phẩm, chi tiết hư hại cũng như khoản phí khắc phục sẽ được gửi đến khách hàng để xác nhận trước khi Drobebox thực hiện việc thu phí.<br/>
        Khoản phí này sẽ tuỳ thuộc vào mức độ thiệt hại cũng như khả năng khắc phục tuy nhiên sẽ không lớn hơn giá trị thật của sản phẩm (giá bán lẻ - khấu hao)
      </>
    ),
  },
];

const Faq = (props) => (
  <FAQWrapper id="faq" {...props}>
    <TitleContainer>
      <Line/>
      <h1>Nàng hỏi <br/> Drobebox trả lời</h1>
    </TitleContainer>
    <FAQContainer>
      {FAQS.map(({ title, content }, index) => (
        <FAQItemContainer>
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        </FAQItemContainer>
      ))}
    </FAQContainer>
  </FAQWrapper>
);


const FAQWrapper = styled.div`
  position: relative;
  display: flex;
  background-color: ${props => props.theme.color.white.regular};
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 94px;
  padding-bottom: 140px;
  padding-left: 107px;
`;

const TitleContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
  h1 {
    width: 227px;
    z-index: 200;
    text-align: right;
    margin-left: 85px;
  }
`

const Line = styled.div `
  width: 131px;
  height: 1px;
  border: solid 1px #1b1b2a;
  margin-top: 28px;
  margin-right: -135px;
  z-index: 200;
`

const FAQContainer = styled.div`
  padding-bottom: 3rem;
  padding-left: 85px;
  display: flex;
  flex: 3;
  flex-wrap: wrap;
`
const FAQItemContainer = styled.div `
  width: 393px;
`
export default Faq;
